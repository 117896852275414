import React from 'react'
import { Aromatizovano, Cheesecake, Klub, Mesavina, Palacinke, Piza, Slana, Sokovi, Topli, Zestoko } from '../assets/images/slider'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";

const ItemSelect = ({ food }: { food: boolean }) => {
    if (food) {
        return (
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className="item-select"
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={220}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 5,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 5,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 5,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                <a href="#PIZZA"><div className='item-select-one'><img src={Piza} alt='Left Pizza' /></div></a>
                <a href="#SENDVIČI"><div className='item-select-one'><img src={Klub} alt='Left Pizza' /></div></a>
                <a href="#SLANE PALAČINKE"><div className='item-select-one'><img src={Slana} alt='Left Pizza' /></div></a>
                <a href="#SLATKE PALAČINKE"><div className='item-select-one'><img src={Palacinke} alt='Left Pizza' /></div></a>
                <a href="#KOLAČI"><div className='item-select-one'><img src={Cheesecake} alt='Left Pizza' /></div></a>
            </Carousel>
            // <div className='item-select'>
            //     <a href="#PIZZA"><div className='item-select-one'><img src={Piza} alt='Left Pizza' /></div></a>
            //     <a href="#SENDVIČI"><div className='item-select-one'><img src={Klub} alt='Left Pizza' /></div></a>
            //     <a href="#SLANE PALAČINKE"><div className='item-select-one'><img src={Slana} alt='Left Pizza' /></div></a>
            //     <a href="#SLATKE PALAČINKE"><div className='item-select-one'><img src={Palacinke} alt='Left Pizza' /></div></a>
            //     <a href="#KOLAČI"><div className='item-select-one'><img src={Cheesecake} alt='Left Pizza' /></div></a>
            // </div>
        )
    } else {
        return (
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className="item-select"
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={220}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 5,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 5,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 5,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                <a href="#AROMATIZOVANA PIĆA"><div className='item-select-one'><img src={Aromatizovano} alt='Left Pizza' /></div></a>
                <a href="#MEŠAVINA PIĆA"><div className='item-select-one'><img src={Mesavina} alt='Left Pizza' /></div></a>
                <a href="#ŽESTOKA PIĆA"><div className='item-select-one'><img src={Zestoko} alt='Left Pizza' /></div></a>
                <a href="#TOPLI NAPICI"><div className='item-select-one'><img src={Topli} alt='Left Pizza' /></div></a>
                <a href="#SOKOVI"><div className='item-select-one'><img src={Sokovi} alt='Left Pizza' /></div></a>
            </Carousel>
            // <div className='item-select'>
            //     <a href="#AROMATIZOVANA PIĆA"><div className='item-select-one'><img src={Aromatizovano} alt='Left Pizza' /></div></a>
            //     <a href="#MEŠAVINA PIĆA"><div className='item-select-one'><img src={Mesavina} alt='Left Pizza' /></div></a>
            //     <a href="#ŽESTOKA PIĆA"><div className='item-select-one'><img src={Zestoko} alt='Left Pizza' /></div></a>
            //     <a href="#TOPLI NAPICI"><div className='item-select-one'><img src={Topli} alt='Left Pizza' /></div></a>
            //     <a href="#SOKOVI"><div className='item-select-one'><img src={Sokovi} alt='Left Pizza' /></div></a>
            // </div>
        )
    }
}

export default ItemSelect