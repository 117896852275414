import React from 'react'
import { Akcija, Bckg, Radnici, Slider1, Slider2, Slider3, Slider4, Slider5 } from '../assets/images/images'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Action = () => {
    const images = [
        Akcija,
        Bckg,
        Akcija,
    ];

    return (
        <div className="akcija">
            <img src={Radnici} alt="Akcija" style={{ width: '100%', borderRadius: '9px' }} />
            {/* <img src={Radnici} alt="Akcija" style={{ width: 'calc(100% - 2rem)', borderRadius: '9px', marginLeft: '1rem', marginRight: '1rem' }} /> */}
            {/* <br />
            <br />
            <Carousel showThumbs={false} showStatus={false} swipeable={true} infiniteLoop={true} interval={3000} autoPlay={true} dynamicHeight={true}>
                <div>
                    <img src={Slider1} alt="Akcija" style={{ width: '100%', borderRadius: '9px' }} />
                </div>
                <div>
                    <img src={Slider2} alt="Akcija" style={{ width: '100%', borderRadius: '9px' }} />
                </div>
                <div>
                    <img src={Slider3} alt="Akcija" style={{ width: '100%', borderRadius: '9px' }} />
                </div>
                <div>
                    <img src={Slider4} alt="Akcija" style={{ width: '100%', borderRadius: '9px' }} />
                </div>
                <div>
                    <img src={Slider5} alt="Akcija" style={{ width: '100%', borderRadius: '9px' }} />
                </div>
            </Carousel> */}
            {/* <img src={Akcija} alt="Akcija" style={{ width: '100%', borderRadius: '9px' }} /> */}
        </div>
    )
}

export default Action