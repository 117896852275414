import { useEffect, useState } from 'react'
// import Header from '../sections/header'
import Footer from '../sections/footer'
// import Slider from '../sections/slider'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../utils/api'
import { ISubcategory, IItem } from '../models/category'
// import { Piza } from '../assets/images/slider'
import ScrollToTop from 'react-scroll-to-top'
import ItemSelect from '../sections/itemSelect'
// import { isTypeAliasDeclaration } from 'typescript'
import NewHeader from '../sections/newHeader'

const Menu = () => {
    const [menu, setMenu] = useState<ISubcategory[]>([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [pice, setPice] = useState(false);

    let { id } = useParams();

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/subcategory/${id}`).then((res) => {
            console.log(res);
            setMenu(res.data);
            document.body.classList.remove('pozadina-hrana');
            document.body.classList.remove('pozadina-pice');
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].name == 'TOPLI NAPICI') {
                    document.body.classList.add('pozadina-pice');
                    setPice(true);
                    break;
                } else {
                    document.body.classList.add('pozadina-hrana');
                    break;
                }
            }
        }).catch((e) => {
            setError(e.message);
            setMenu([]);
        })
        setIsLoading(false);
    }, [])

    return (
        <>
            <ScrollToTop style={{ zIndex: 20 }} smooth color="#6f00ff" />
            <div className='container'>
                <NewHeader />
            </div>
            <div className='breadcrumbs'>
                <a href="/">Početna</a>
                <div style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>/</div>
                <div>{pice ? 'Piće' : 'Hrana'}</div>
            </div>
            {/* <Slider /> */}
            <ItemSelect food={!pice} />
            <div className='main-menu'>
                <div className='left-main-menu'>
                    {menu.map((men: ISubcategory) => {
                        return (
                            <div key={men.id} id={men.name}>
                                <div className='menu-main-title'>
                                    <h2>{men.name}</h2>
                                </div>
                                {men.name == "PASTE" ? <p style={{ fontSize: "1.5em", marginTop: 0, fontStyle: 'italic' }}>Spaghetti, Tagliatelle, Penne</p> : <></>}
                                <div>
                                    {men.items.map((item: IItem) => {
                                        return (
                                            <div className='menu-card'>
                                                <div className='flex-item-price'>
                                                    <div>
                                                        <h3 style={{ fontSize: '1.5em', fontWeight: '500' }}>{item.name}</h3>
                                                        <p style={{ fontStyle: 'italic', fontWeight: 300 }}>{item.description}</p>
                                                    </div>
                                                    {item.price2 == null ? (
                                                        <div style={{ minWidth: '8rem' }}>
                                                            <p style={{ fontSize: '1.5em', textAlign: 'right' }}>{item.price} RSD</p>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <p style={{ fontSize: '1.5em', textAlign: 'right' }}>{item.price} / {item.price2} RSD</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div >
            <div className='container'>
                <Footer />
            </div>
        </>
    )
}

export default Menu