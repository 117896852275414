import Header from '../sections/header'
import Footer from '../sections/footer'
import IndexButtons from '../sections/indexButtons'
import Action from '../sections/action'
import NewHeader from '../sections/newHeader'
import { Radnici } from '../assets/images/images'

const Main = () => {
    return (
        <div className='container'>
            <NewHeader />
            <IndexButtons />
            <Action />
            <Footer />
        </div>
    )
}

export default Main