import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../components/StrictModeDroppable';
import { Link, useParams } from 'react-router-dom';
import { API_URL } from '../../utils/api';
import axios from 'axios';
import { ISubcategory } from '../../models/category';

const Subcategories = () => {
    const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [editData, setEditData] = useState<ISubcategory | null>(null);

    const [name, setName] = useState("");

    const { id } = useParams();

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        let submitPromise = null;

        if (editData) {
            submitPromise = axios.put(`${API_URL}/api/subcategory/${editData.id}`, {
                name
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
        } else {
            submitPromise = axios.post(`${API_URL}/api/subcategory`, {
                name,
                category_id: id
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
        }

        submitPromise.then((res) => {
            if (editData) {
                const newState = subcategories.map(obj => {
                    if (obj.id === res.data.id) {
                        return { ...obj, name: res.data.name };
                    }

                    return obj;
                });

                setSubcategories(newState);
            } else {
                setSubcategories((prevState) => {
                    return [...prevState, res.data];
                });
            }

            setEditData(null);
            setName('');
        })
    }

    const handleSubcategoryDelete = (id: number) => {
        axios.delete(`${API_URL}/api/subcategory/${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setSubcategories((prevState) => {
                return prevState.filter((cat: ISubcategory) => cat.id !== res.data.id)
            });
            // HENDLUJ DA IZBRISE IZ STATE KATEGORIJU
        })
    }

    const handleSubcategoryUpdate = (subcategory: ISubcategory) => {
        setEditData(subcategory);
        setName(subcategory.name);
    }

    const handleFormReset = () => {
        setEditData(null);
        setName('');
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/subcategory/${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setSubcategories(res.data);
        }).catch((e) => {
            setError(e.message);
            setSubcategories([]);
        })
        setIsLoading(false);
    }, [])

    function handleDragEnd(result: any) {
        // Get the updated item list after drag and drop
        const updatedItems = Array.from(subcategories);
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);

        // Get the list of item IDs and their indexes
        const updatedItemIds = updatedItems.map((item: ISubcategory) => item.id);
        const updatedItemIndexes = updatedItems.map((item, index) => index);

        // Do something with the updated items and their indexes
        setSubcategories(updatedItems);
        axios.post(`${API_URL}/api/subcategory/update-index`, {
            updatedItemIds,
            updatedItemIndexes
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            console.log(updatedItemIds);
            console.log(updatedItemIndexes);
        });
    }

    return (
        <div className='admin-categories'>
            <div className='admin-add-category'>
                <div className='admin-form-title'>{editData ? 'Izmeni izabranu podkategoriju' : 'Dodaj podkategoriju'}</div>
                <form className='admin-form' onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Naziv:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                    <button className='admin-submit' type="submit">{editData ? 'Izmeni' : 'Dodaj'}</button>
                    {editData && <button className='admin-reset' onClick={handleFormReset}>Otkazi</button>}
                </form>
            </div>
            <div className='admin-title'> Lista svih podkategorija za zadatu kategoriju </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className='subcategory-selector'>
                    <StrictModeDroppable droppableId="my-droppable">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {subcategories.map((subcategory: ISubcategory, index: number) => {
                                    return (
                                        <Draggable key={subcategory.id} draggableId={`id${subcategory.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className='admin-subcategory'>
                                                        <Link to={`/admin/items/${subcategory.id}`} style={{ fontSize: '2.5em', color: 'white' }}>{subcategory.name}</Link>
                                                        <div className='admin-buttons'>
                                                            <button className='admin-button admin-green' onClick={() => handleSubcategoryUpdate(subcategory)}>Izmeni</button>
                                                            <button className='admin-button admin-red' onClick={() => handleSubcategoryDelete(subcategory.id)}>Obrisi</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )}
                    </StrictModeDroppable>
                </div>
            </DragDropContext>
        </div>
    )
}

export default Subcategories