import React from 'react'

const Footer = () => {
    return (
        <div className="created-by">
            Created by <a href="https://upfoxstudio.com/">upfoxstudio.com</a>
        </div>
    )
}

export default Footer